// @flow
import sanityClient from '@sanity/client';
import { useState } from 'react';
import type { SanityConfig, SanityQuery } from '../../types';

interface Props {
  config: SanityConfig;
  queries: Array<SanityQuery>;
  debug: boolean;
}

/**
 *
 * @param config
 * @param queries
 * @param debug
 * @returns {{call: (function(*, {}=): Promise<any>), subscribe: ((function(*): Promise<void>)|*), unsubscribe: unsubscribe}}
 */
export const useSanity = ({ config, queries, debug }: Props) => {
  const [client] = useState(sanityClient(config));
  const [subscriptionData, setSubscriptionData] = useState();
  const [subscription, setSubscription] = useState();

  const getQuery = name => {
    const found = Object.keys(queries)
      .filter(n => n === name)
      .reduce((obj, key) => queries[key], {});

    if (debug) console.log(`useSanity:${name}:getQuery`, found);

    return found;
  };

  const getSubscription = name => subscription.filter(n => n.name === name);

  /**
   * Query sanity
   * @name useSanity.call
   * @param name
   * @param params
   * @returns {Promise<*>}
   */
  const call = async (name, params = {}) => {
    const query = getQuery(name);

    if (debug)
      console.log(`useSanity:${name}:query`, { queries, query, params });

    const data = await client.fetch(query, params).catch(error => {
      if (debug) console.log(`useSanity:${name}:error`, error);

      return undefined;
    });

    if (debug) console.log(`useSanity:${name}:data`, data);

    return data;
  };

  /**
   *
   * @param name
   * @returns {Promise<void>}
   */
  const subscribe = async name => {
    try {
      const { query, params } = getQuery(name);

      const subscription = client
        .listen(query, params)
        .subscribe(update =>
          setSubscriptionData({
            ...subscriptionData,
            [name]: { data: update },
          }),
        );

      setSubscription({ ...subscription, [name]: subscription });
    } catch (e) {
      if (e) await Promise.reject(`useApi:${name}`);
    }
  };

  /**
   * Stop listening to a sanity subscription
   * @param name
   */
  const unsubscribe = name => {
    const subscription = getSubscription(name);

    subscription.unsubscribe();
  };

  return {
    call,
    subscribe,
    unsubscribe,
  };
};
