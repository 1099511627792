import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';

export const Confirmation = ({ open, title, body, close, actions }) => (
  <Dialog open={open} onClose={close}>
    <DialogTitle>
      <Typography variant={'h3'}>{title}</Typography>
    </DialogTitle>

    <DialogContent>
      <DialogContentText>
        <Typography variant={'body1'}>{body}</Typography>
      </DialogContentText>
    </DialogContent>

    <DialogActions>
      {Array.isArray(actions) &&
        actions.map(action => (
          <Button onClick={action.func}>{action.text}</Button>
        ))}
    </DialogActions>
  </Dialog>
);

Confirmation.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  body: PropTypes.string,
  close: PropTypes.func,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      func: PropTypes.func,
    }),
  ),
};

Confirmation.defaultProps = {
  open: false,
  title: '',
  body: '',
  close: () => {},
  actions: [{ text: '', func: () => {} }],
};
