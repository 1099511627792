import styled from 'styled-components';

const MapWrapper = styled.section`
  width: 70%;
  height: ${({ mapHeight }) => mapHeight};

  border: 2px solid black;
  border-radius: 7px;

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-content: center;
`;

export { MapWrapper };
