import React from 'react';
import PropTypes from 'prop-types';
import { PrayerRequestStyle } from './PrayerRequestStyle';
import { EmailJsForm } from '../components/info/EmailJsForm';
import { Typography } from '@material-ui/core';

export const PrayerRequests = ({ requestRef }) => {
  return (
    <PrayerRequestStyle ref={requestRef}>
      <Typography variant={'h4'} align={'center'} color={'secondary'}>
        Prayer Requests
      </Typography>

      <section className={'formPadding'}>
        <EmailJsForm />
      </section>
    </PrayerRequestStyle>
  );
};

PrayerRequests.propTypes = {
  requestRef: PropTypes.node,
};

PrayerRequests.defaultProps = {};
