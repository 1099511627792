import styled from 'styled-components';
import Button from '@material-ui/core/Button';

const Wrapper = styled.div`
  width: calc(100vw - 1%);
  height: 4.5rem;
  padding: 0.5%;

  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;

  background-color: #2e2e2e;
`;

const FtLeft = styled.section`
  width: calc(calc(100% - 2rem) - 1%);
  height: 100%;

  display: flex;
  flex-flow: column nowrap;
`;

const StyledBtn = styled(Button)`
  font-family: 'Fugaz One', cursive;
  margin-right: 1.4% !important;
`;

const TitleWrapper = styled.section`
  margin-left: 0.7%;
`;

const DevLogo = styled.img`
  width: 2rem;
  padding: 0 0.5%;
`;

export { Wrapper, FtLeft, DevLogo, TitleWrapper, StyledBtn };
