import { Landing } from '../pages/Landing';

const routes = [
  {
    path: '/',
    component: Landing,
  },
];

export { routes };
