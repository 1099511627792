import styled from 'styled-components';

const PrayerRequestStyle = styled.div`
  width: 100%;
  padding: 4% 0;
  display: flex;
  flex-flow: column wrap;
  justify-content: space-evenly;
  align-content: center;
  
  .formPadding {
    padding: 4%;
  }
`;

export {
  PrayerRequestStyle
}