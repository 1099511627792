import styled from 'styled-components';
import { Card, CardContent } from '@material-ui/core';

const StyledCard = styled(Card)`
  width: 92%;
  padding: 2% 4%;
  min-height: 75%;

  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
`;

const TextBoundary = styled(CardContent)`
  width: 100%;
  margin:auto;
  display: inline-flex;
  justify-content: center;
  
  &> .textWrapper {
    width: 85%;
    display: flex;
    flex-flow: column nowrap;
    justify-items: center;
    justify-content: center;
  }
`;

const StyledCardContent = styled(CardContent)`
  max-height: 25rem;
  display: flex;
  flex-flow: column nowrap;

  justify-content: space-evenly;
`;

const ContentWrapper = styled.section`
  min-height: 80%;
`;

export { StyledCard, TextBoundary, StyledCardContent, ContentWrapper };
