/*eslint-disable no-unused-vars*/
import React, { useState, useEffect, useRef } from 'react';
import emailjs, { init } from 'emailjs-com';
import { config } from '../../js/config';
import styled from 'styled-components';
import { Typography, Paper, Button, TextField } from '@material-ui/core';
import { Confirmation } from './Confirmation';

const FormCard = styled(Paper)`
  width: 45rem;
  max-width: 80vw;
  height: 25rem;
  margin: 1rem;
  padding: 1.5rem 1rem;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-evenly;
  align-content: center;
  align-items: center;
  background-color: white;

  #row-col {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    align-content: center;
    align-items: center;
  }

  #message-lines {
    width: 85%;
  }

  #action-row {
    width: 70%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    align-items: center;
  }
`;

export const EmailJsForm = () => {
  init(config.emailJs.user);

  const [open, setOpen] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [emailJsMessage, setEmailJsMessage] = useState({}); //{ status: Number, text: String }

  const form = useRef();

  const sendEmail = e => {
    e.preventDefault();

    emailjs
      .sendForm(
        config.emailJs.service,
        config.emailJs.template,
        form.current,
        config.emailJs.user,
      )
      .then(result => {
        setEmailJsMessage(result);
        setEmail('');
        setName('');
        setMessage('');
      })
      .catch(error => console.log(error.text));
  };

  useEffect(() => {
    if (emailJsMessage.status === 200) setOpen(true);
  }, [emailJsMessage]);

  return (
    <>
      <Confirmation
        open={open}
        title={'Success!'}
        body={'Your prayer request has been successfully sent.'}
        close={() => setOpen(false)}
        actions={[
          {
            text: 'close',
            func: () => {
              setOpen(false);
              setEmailJsMessage({});
            },
          },
        ]}
      />

      <form ref={form} onSubmit={sendEmail}>
        <FormCard elevation={3}>
          <section id={'row-col'}>
            <TextField
              type={'text'}
              variant={'outlined'}
              placeholder={'Name'}
              name={'name'}
              label={'Name'}
              isRequired={false}
              value={name}
              onChange={e => {
                e.preventDefault();
                setName(e.target.value);
              }}
            />

            <TextField
              type={'email'}
              variant={'outlined'}
              placeholder={'Email'}
              name={'email'}
              label={'Email'}
              value={email}
              onChange={e => {
                e.preventDefault();
                setEmail(e.target.value);
              }}
            />
          </section>

          <section id={'message-lines'}>
            <TextField
              type={'text'}
              placeholder={'Prayer Request'}
              fullWidth
              multiline={true}
              minRows={10}
              maxRows={24}
              name={'message'}
              label={'Request'}
              variant={'outlined'}
              value={message}
              onChange={e => {
                e.preventDefault();
                setMessage(e.target.value);
              }}
            />
          </section>

          <section id={'action-row'}>
            <Button
              type={'submit'}
              value={'send'}
              color={'primary'}
              variant={'contained'}
              size={'large'}
              fullWidth
              disabled={!message}
            >
              <Typography
                variant={config.isMobile ? `button` : `h5`}
                align={'center'}
              >
                Send
              </Typography>
            </Button>
          </section>
        </FormCard>
      </form>
    </>
  );
};
