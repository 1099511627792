import React from 'react';
import { Button, CardContent, Typography } from '@material-ui/core';
import { StyledCard, TextBoundary } from './style/InfoCardStyle';
import { config } from '../../js/config';
import { scrollTo } from '../../utils/domUtils';

export const InfoCard = ({ contents }) => (
  <StyledCard>
    <Typography gutterBottom variant={config.isMobile ? 'h4' : 'h3'}>
      {contents.title}
    </Typography>

    <TextBoundary>
      <section className={'textWrapper'}>
      {Array.isArray(contents.lines) &&
        contents.lines.map((line, index) => (
          <Typography
            key={index}
            align={'center'}
            variant={'subtitle1'}
            color={'textSecondary'}
          >
            {line._line.text}
          </Typography>
        ))}
      </section>
    </TextBoundary>

    <section>
      <Button
        variant={'contained'}
        color={'primary'}
        onClick={() => scrollTo('announcementList')}
      >
        Browse Announcements
      </Button>
    </section>
  </StyledCard>
);
