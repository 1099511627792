// @flow
/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect } from 'react';
import { config } from '../../js/config';
import Slider from 'react-slick';
import { Box } from './style/SideNotesStyle';
import { Typography } from '@material-ui/core';
import { CarouselBody } from './style/EventStyle';
import moment from 'moment';
import { StyledA } from './style/EventCardStyle';
import { useSanity } from '../hooks/useSanity';
import { useRecoilState } from 'recoil';
import { sideNotes } from '../../state/atoms';

export const SideNotes = () => {
  const { sanity_config, sanity_queries } = config;
  const { call } = useSanity({
    config: sanity_config,
    queries: sanity_queries,
  });

  const [notes, setNotes] = useRecoilState(sideNotes);

  const settings = {
    swipeToSlide: true,
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: true,
  };

  const hasUrl = note => {
    if (note.url || note.file_url) {
      return note.file_url ? note.file_url : note.url;
    }
  };

  useEffect(() => {
    const getNotes = async () => {
      const data = await call('side_notes');

      //todo -> destructure here
      if (Array.isArray(data) && data.length > 0) {
        console.log('data test', data);
        setNotes(data.filter(e => moment().isSameOrBefore(e.ts_exp)));
      }
    };

    getNotes();
  }, []);

  return (
    <Box notes={notes}>
      <CarouselBody>
        <Slider {...settings}>
          {notes.map((note, index) => (
            <StyledA
              key={index}
              disabled={
                !Object.keys(note).includes('url') &&
                !Object.keys(note).includes('file_url')
              }
              href={hasUrl(note)}
            >
              <Typography variant={'subtitle1'} align={'center'}>
                {note.msg}
              </Typography>
            </StyledA>
          ))}
        </Slider>
      </CarouselBody>
    </Box>
  );
};
