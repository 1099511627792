import styled from 'styled-components';
import { Paper } from '@material-ui/core';

const AnWrapper = styled.div`
  width: 100vw;
  padding: 0.6% 0;

  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  text-align: center;

  overflow-y: hidden;
`;

const AnnDetail = styled.div`
  width: 100%;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  & > .banner {
    width: 100%;
    height: 17rem;
    background: #232b2b;
    z-index: 7;
    box-shadow: 0 1.7px 4px rgba(0, 0, 0, 0.031),
      0 4.8px 11.1px rgba(0, 0, 0, 0.045), 0 11.5px 26.8px rgba(0, 0, 0, 0.059),
      0 38px 89px rgba(0, 0, 0, 0.09);
  }

  & > .banner > img {
    height: 100%;
    min-width: 50%;
    max-width: 80%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
`;

const AnnList = styled.div`
  width: 90%;
  height: 2px;
  max-height: ${({ mobile }) => (mobile ? '30' : '45')}vh;
  margin: 1em 0.5em;

  overflow-y: auto;
  overflow-x: hidden;

  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-evenly;
  //background-color: #f4f4f4;
  //background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%237e7c80' fill-opacity='0.4' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
`;

const DetailsWrapper = styled.section`
  height: auto;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  align-content: center;
  align-items: center;
`;

export { AnnList, AnnDetail, AnWrapper, DetailsWrapper };
