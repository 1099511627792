// @flow
/*eslint-disable react-hooks/exhaustive-deps*/
import React, { useState, useEffect, useRef } from 'react';
import {
  AnWrapper,
  AnnDetail,
  DetailsWrapper,
} from './style/AnnouncementsStyle';
import '../../styles/views.scss';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import { InfoCard } from './InfoCard';
import { InfoCardSmall } from './InfoCardSmall';
import { config } from '../../js/config';
import { useIntersection } from '../hooks/useIntersection';
import { XyzTransitionGroup } from '@animxyz/react';
import { scrollTo } from '../../utils/domUtils';
import { useSanity } from '../hooks/useSanity';

export const Announcements = ({ annRef }) => {
  const { sanity_config, sanity_queries } = config;
  const { call } = useSanity({
    config: sanity_config,
    queries: sanity_queries,
    debug: true,
  });

  const [active, setActive] = useState(0);
  const [slides, setSlides] = useState([]);
  const [triggered, setTriggered] = useState(false);

  const ref = useRef(undefined);

  useEffect(() => {
    const getAnnouncements = async () => {
      const data = await call('announcements');

      if (Array.isArray(data) && data.length > 0) {
        const raw = data.sort((a, b) =>
          moment(a._createdAt).isBefore(b._createdAt)
            ? 1
            : moment(a._createdAt).isAfter(b._createdAt)
            ? -1
            : 0,
        );

        // set all the slides
        setSlides(raw);

        //set the active slide
        setActive(raw[0]);
      }
    };

    getAnnouncements();
  }, []);

  // Animation trigger for showing the cards once
  const visible = useIntersection(ref, '0px');
  useEffect(() => {
    if (!triggered) setTriggered(visible);
  }, [visible]);

  return (
    <AnWrapper ref={annRef}>
      <Typography
        gutterBottom
        variant='h3'
        color={'secondary'}
        align={'center'}
      >
        Announcements
      </Typography>

      <DetailsWrapper ref={ref}>
        <XyzTransitionGroup
          id={'announcementList'}
          className={`annList ${config.isMobile && 'annMobile'}`}
          xyz={'stagger fade small out-down out-rotate-right'}
        >
          {triggered &&
            slides.map((slide, index) => (
              <div key={index}>
                <InfoCardSmall
                  action={() => {
                    setActive(slides[index]);
                    scrollTo('info-detail');
                  }}
                  contents={slide}
                  clicked={index === active}
                />
              </div>
            ))}
        </XyzTransitionGroup>

        <AnnDetail id={'info-detail'} mobile={config.isMobile}>
          {/* Banner if supplied */}
          {active.imageUrl && (
            <section className={'banner'}>
              <img src={active.imageUrl} alt={'announcementImage'} />
            </section>
          )}

          {/* Selected Info */}
          <InfoCard contents={active} />
        </AnnDetail>
      </DetailsWrapper>
    </AnWrapper>
  );
};
