import React from 'react';
import { EventCardStyle, StyledCardMedia, StyledA } from './style/EventCardStyle';
import { CardContent, Typography } from '@material-ui/core';

export const EventCard = ({ data }) => {
  return (
    <EventCardStyle>
      <StyledA
        disabled={!Object.keys(data).includes('url')}
        target='_blank'
        href={data.url}
      >
        <StyledCardMedia image={data.imageUrl} title={data.title} />

        <CardContent>
          <Typography gutterBottom variant='h5' component='h2'>
            {data.title}
          </Typography>

          {Array.isArray(data.lines) &&
            data.lines.map((line, index) => (
              <Typography
                key={index}
                align={'center'}
                variant={'body2'}
                color={'textSecondary'}
                component={'p'}
              >
                {line._line.text}
              </Typography>
            ))}
        </CardContent>
      </StyledA>
    </EventCardStyle>
  );
};
