import styled from 'styled-components';

const Box = styled.div`
  display: ${({ notes }) => (notes.length > 0 ? 'block' : 'none')};
  height: 1.75rem;
  width: 100%;
  z-index: 3;
  background-color: #d65562;
  background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%232b2b2b' fill-opacity='0.28' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
`;

export { Box };
