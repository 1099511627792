// @flow
/*eslint-disable react-hooks/exhaustive-deps*/
import React, { useState, useEffect, createRef } from 'react';
import { MapBox, Wrapper } from './LandingStyle';
import { Nav } from '../components/nav/Nav';
import { config } from '../js/config';
import { Events } from '../components/info/Events';
import { Announcements } from '../components/info/Announcements';
import { PastorInfo } from '../components/info/PastorInfo';
import { Footer } from '../components/nav/Footer';
import { Map } from '../components/geo/Map';
import { Typography } from '@material-ui/core';
import { SideNotes } from '../components/info/SideNotes';
import { PrayerRequests } from './PrayerRequests';
import { useSanity } from '../components/hooks/useSanity';
import { useRecoilState } from 'recoil';
import { sideNotes } from '../state/atoms';

export const Landing = () => {
  const { sanity_config, sanity_queries } = config;
  const { call } = useSanity({
    config: sanity_config,
    queries: sanity_queries,
  });

  const notes = useRecoilState(sideNotes);

  const [locations, setLocations] = useState();
  const [pastor, setPastor] = useState([]);

  const [eventRef] = useState(createRef());
  const [annRef] = useState(createRef());
  const [requestRef] = useState(createRef());

  const scrollToRef = ref => ref.current.scrollIntoView({ behavior: 'smooth' });

  useEffect(() => {
    const gatherData = async () => {
      const locs = await call('locations');
      const pas = await call('pastor');

      //todo -> destructure here
      if (Array.isArray(locs)) setLocations(locs[0]);

      setPastor(pas);
    };

    gatherData();
  }, []);

  return (
    <Wrapper>
      <SideNotes />

      <Nav
        toEvents={() => scrollToRef(eventRef)}
        toAnn={() => scrollToRef(annRef)}
        toRequests={() => scrollToRef(requestRef)}
        location={locations}
        hasNotes={notes.length > 0}
      />

      {locations && (
        <MapBox elevation={3}>
          <Typography gutterBottom variant={'h3'} align={'center'}>
            You Can Find Us Here!
          </Typography>

          <section id={'mapAddrs'}>
            <Typography
              gutterBottom
              variant={`${Boolean(config.isMobile) ? 'h6' : 'h5'}`}
              align={'center'}
            >
              {locations.address} {Boolean(config.isMobile) ? <br /> : '-'}{' '}
              {locations.address_cont}
            </Typography>
          </section>

          <Map
            center={[locations.geopoint.lat, locations.geopoint.lng]}
            markers={[
              {
                address: locations.address,
                addressCont: locations.address_cont,
                mark: [locations.geopoint.lat, locations.geopoint.lng],
              },
            ]}
          />
        </MapBox>
      )}

      <section className={'gradient'}>
        <Events evRef={eventRef} />

        <Announcements annRef={annRef} />

        <PrayerRequests requestRef={requestRef} />
      </section>

      <PastorInfo info={pastor} />

      <Footer
        toEvents={() => scrollToRef(eventRef)}
        toAnn={() => scrollToRef(annRef)}
        toRequests={() => scrollToRef(requestRef)}
        info={locations}
      />
    </Wrapper>
  );
};
