import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;

  background-image: url(${props => (props.bkUrl ? props.bkUrl : '#2E2E2E')});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
`;

const Filter = styled.section`
  background: linear-gradient(
    0deg,
    rgba(196, 196, 196, 1) 0%,
    rgba(196, 196, 196, 0) 100%
  );
  width: 100%;
  height: 100%;

  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;
`;

const TextWrapper = styled.section`
  padding-left: 1%;
`;

export { Wrapper, Filter, TextWrapper };
