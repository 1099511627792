import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  min-height: 75vh;
  padding: 2% 0;
  text-align: center;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
`;

const CarouselBody = styled.section`
  width: ${({ mobile }) => (mobile === true ? '80%' : '90%')};
  margin: auto;
  justify-self: center;

  .slick-prev::before {
    color: dimgrey;
  }

  .slick-next::before {
    color: dimgrey;
  }
`;

const LoadingWrapper = styled.section`
  width: 100%;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
`;

export { Wrapper, CarouselBody, LoadingWrapper };
