import styled from 'styled-components';
import { Card, CardActionArea } from '@material-ui/core';

const StyledCard = styled(Card)`
  width: ${({ mobile }) => mobile ? '70vw' : '16em'};
  height: 2px;
  min-height: 7em;
  margin: 1%;
  padding: ${({ mobile }) => mobile ? '0.5% 3%' : '1%'};

  &.MuiCard-root {
    overflow: unset;
  }
`;

const StyledCardActionArea = styled(CardActionArea)`
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: center;
`;

export { StyledCard, StyledCardActionArea };
