// @flow
/*eslint-disable react-hooks/exhaustive-deps*/
import React, { useState, useEffect } from 'react';
import { Wrapper, CarouselBody, LoadingWrapper } from './style/EventStyle';
import { EventCard } from './EventCard';
import Slider from 'react-slick';
import Typography from '@material-ui/core/Typography';
import { CircleLoader } from 'react-spinners';
import { config } from '../../js/config';
import { useSanity } from '../hooks/useSanity';

export const Events = ({ evRef }) => {
  const { sanity_config, sanity_queries } = config;
  const { call } = useSanity({
    config: sanity_config,
    queries: sanity_queries,
  });

  const [events, setEvents] = useState([]);

  const settings = {
    swipeToSlide: true,
    dots: true,
    infinite: true,
    slidesToShow: config.isMobile ? 1 : events.length > 1 ? 2 : 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: true,
  };

  useEffect(() => {
    const getEvents = async () => {
      const data = await call('events');

      if (Array.isArray(data)) setEvents(data);
    };

    getEvents();
  }, []);

  return (
    <Wrapper ref={evRef}>
      <Typography gutterBottom variant='h3' color={'secondary'}>
        Events
      </Typography>

      <CarouselBody mobile={config.isMobile}>
        {events.length > 0 ? (
          <Slider {...settings}>
            {events.map((e, index) => (
              <EventCard key={index} data={e} />
            ))}
          </Slider>
        ) : (
          <LoadingWrapper>
            {events.length === 0 ? (
              <Typography variant={'h3'}>
                There are no events at this time
              </Typography>
            ) : (
              <CircleLoader color={'#E50E27'} />
            )}
          </LoadingWrapper>
        )}
      </CarouselBody>
    </Wrapper>
  );
};
