// @flow
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Route, Switch } from 'react-router-dom';
import { routes } from '../../routes/routes';
import '@animxyz/core';
import { RecoilRoot } from 'recoil';

const App = () => {
  return (
    <RecoilRoot>
      <Router>
        <Switch>
          {routes.map((route, index) =>
            route.path === '/' ? (
              <Route
                key={index}
                exact
                path={route.path}
                component={route.component}
              />
            ) : (
              <Route
                key={index}
                path={route.path}
                component={route.component}
              />
            ),
          )}
        </Switch>
      </Router>
    </RecoilRoot>
  );
};

export default App;
