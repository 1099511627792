import React from 'react';
import { Typography } from '@material-ui/core';
import { config } from '../../js/config';
import { StyledCard, StyledCardActionArea } from './style/InfoCardSmallStyle';
import moment from 'moment';

export const InfoCardSmall = ({ contents, action }) => (
  <StyledCard mobile={config.isMobile}>
    <StyledCardActionArea onClick={action}>
      <Typography variant='h5' color='primary' component='p' align='center'>
        {contents.title}
      </Typography>
    </StyledCardActionArea>
  </StyledCard>
);
