import React, { useState } from 'react';
import {
  Wrapper,
  FtLeft,
  DevLogo,
  StyledBtn,
  TitleWrapper,
} from './style/FooterStyle';
import { Typography } from '@material-ui/core';
import B from '../../assets/b-logo-light.png';
import { config } from '../../js/config';

export const Footer = ({ toEvents, toAnn, toRequests }) => {
  const [options] = useState([
    {
      text: 'Events',
      action: toEvents,
    },
    {
      text: 'Announcements',
      action: toAnn,
    },
    {
      text: 'Prayer Requests',
      action: toRequests,
    },
  ]);

  return (
    <Wrapper>
      <FtLeft>
        <TitleWrapper>
          <Typography
            gutterBottom
            variant={'h5'}
            component={'h5'}
            color={'secondary'}
          >
            New Zion UMC
          </Typography>
        </TitleWrapper>

        <span>
          {options.map(option => (
            <StyledBtn
              key={option.text}
              color={'secondary'}
              onClick={option.action}
            >
              {option.text}
            </StyledBtn>
          ))}
        </span>
      </FtLeft>

      <a href={config.urls.dev}>
        <DevLogo src={B} alt={'s'} />
      </a>
    </Wrapper>
  );
};
