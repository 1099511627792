/*eslint-disable react-hooks/exhaustive-deps*/
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { MapWrapper } from './style/MapStyle';
import L from 'leaflet';
import { config } from '../../js/config';

export const Map = ({ center, markers, conf }) => {
  let initialized = false;
  let map = {};

  useEffect(() => {
    if (!initialized) {
      map = L.map('map').setView(center, conf.zoom);

      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution:
          '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      }).addTo(map);

      markers.forEach(marker => {
        L.marker(marker.mark)
          .addTo(map)
          .bindPopup(`${marker.address} ${marker.addressCont}`);
      });

      initialized = true;
    }
  }, []);

  return (
    <MapWrapper
      id={'map'}
      mobile={config.isMobile}
      mapHeight={conf.mapHeight}
    />
  );
};

Map.propTypes = {
  center: PropTypes.array,
  markers: PropTypes.arrayOf(PropTypes.object),
  conf: PropTypes.object,
};

Map.defaultProps = {
  conf: {
    zoom: 14,
    mapHeight: '15rem',
    mapWidth: '40rem',
  },
};
