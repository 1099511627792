import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Wrapper, Filter, TextWrapper } from './style/PastorInfoStyle';
import { Typography } from '@material-ui/core';

export const PastorInfo = ({ info }) => {
  const [pastorInfo, setPastorInfo] = useState([]);
  const [url, setUrl] = useState();

  const formatPhone = phone => {
    const ph = phone.toString();

    if (ph.length === 10)
      return (
        '(' +
        ph.substring(0, 3) +
        ') ' +
        ph.substring(3, 6) +
        '-' +
        ph.substring(6, 10)
      );
    else return '';
  };

  useEffect(() => {
    if (info[0]) {
      const { name, phone, email, imageUrl } = info[0];

      const _title = 'Pastoral Care';
      const _phone = `Phone: ${formatPhone(phone)}`;
      const _email = `Email: ${email}`;

      setPastorInfo([_title, name, _phone, _email]);
      setUrl(imageUrl);
    }
  }, [info]);

  return (
    <Wrapper bkUrl={url}>
      <Filter>
        <TextWrapper>
          {pastorInfo.length > 0 &&
            pastorInfo.map((info, index) => (
              <Typography
                key={index}
                gutterBottom
                variant={index === 0 ? 'h3' : 'h5'}
              >
                {info}
              </Typography>
            ))}
        </TextWrapper>
      </Filter>
    </Wrapper>
  );
};

PastorInfo.propTypes = {
  info: PropTypes.array,
};
