import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './components/app/App';
import * as serviceWorker from './js/serviceWorker';
import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  :root {
    --umcRed: #E50E27;
  }
  
  html,body {
    width: 100%;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
  }
`;

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#2E2E2E',
      main: '#2E2E2E',
      dark: '#000000',
    },
    secondary: {
      light: '#FFFFFF',
      main: '#FFFFFF',
      dark: '#F4F4F4',
    },
  },
  typography: {
    h1: {
      fontFamily: 'Fugaz One, cursive',
      fontSize: '3.4em',
    },
    h2: {
      fontFamily: 'Fugaz One, cursive',
    },
    h3: {
      fontFamily: 'Titillium Web, sans-serif',
    },
    h4: {
      fontFamily: 'Titillium Web, sans-serif',
    },
    h5: {
      fontFamily: 'Titillium Web, sans-serif',
    },
    h6: {
      fontFamily: 'Titillium Web, sans-serif',
    },
  },
});

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <ThemeProvider theme={theme}>
    <React.StrictMode>
      <GlobalStyle />
      <App />
    </React.StrictMode>
  </ThemeProvider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
