const defaults = {
  isMobile: typeof window.orientation !== 'undefined',
  // isMobile: true,
  sanity_config: {
    projectId: process.env.REACT_APP_SANITY_PROJECT_ID,
    dataset: process.env.REACT_APP_SANITY_DATASET,
    token: process.env.REACT_APP_SANITY_TOKEN,
    useCdn: false,
  },
  emailJs: {
    service: process.env.REACT_APP_EMAILJS_SERVICE_ID,
    template: process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
    user: process.env.REACT_APP_EMAILJS_USER_ID,
  },
  sanity_queries: {
    events: process.env.REACT_APP_SANITY_EVENT_QUERY,
    announcements: process.env.REACT_APP_SANITY_ANNOUNCEMENT_QUERY,
    locations: process.env.REACT_APP_SANITY_LOCATION_QUERY,
    pastor: process.env.REACT_APP_SANITY_PASTOR_QUERY,
    side_notes: process.env.REACT_APP_QUERY_SIDE_NOTES,
  },
  announcement_config: {
    text_crop_substring: [0, 20],
  },
  urls: {
    dev: process.env.REACT_APP_DEV_URL,
  },
};

const init_config = () => {
  const tmp_config = { ...defaults };

  return tmp_config;
};

const config = init_config();

export { config };
